window.addEventListener('scroll', function () {
  var defaultLogo = document.querySelector('.default-logo');
  var orangeLogo = document.querySelector('.orange-logo');
  if (window.scrollY >= window.innerHeight * 0.8) {
    defaultLogo.classList.remove('show-orange');
    orangeLogo.classList.add('show-orange');
  } else {
    defaultLogo.classList.add('show-orange');
    orangeLogo.classList.remove('show-orange');
  }
});